<template>
    <section class="section-wrapper">
        <slot />
    </section>
</template>

<script>
export default {
    name: 'SectionWrapper'
}
</script>

<style scoped>
    .section-wrapper {
        padding: 100px;
    }
    @media screen and (max-width: 1000px) {
        .section-wrapper {
            padding: 70px 70px 100px 70px;
        }
    }
</style>