<template>
    <SectionWrapper>
        <HeroSection />
    </SectionWrapper>
</template>

<script>
import SectionWrapper from '../../shared/components/SectionWrapper.vue';
import HeroSection from '../website-components/kareem/HeroSection.vue';
export default {
    name: 'KareemPage',
    components: { SectionWrapper, HeroSection }
}
</script>