<template>
    <div class="section illustration wow fadeInUp animated" data-wow-duration="1s">
        <img class="section__img" src="../../../../../public/assets/images/website/kareem/logo.svg" alt="logo">
        <h1 class="section__title">
            بوابة التبرعات الجديدة في مؤسسة التميز التنموية
            قم بتصفح الفرص والحالات و تعرف على الحالات الأشد حاجةً
            فالآن جميع فرص التبرع في مكانٍ واحد !
        </h1>
        <a href="https://kareem.t-tamayoz.com/"  target="_blank" class="section__btn">تبرع الآن</a>
    </div>
</template>

<script>
export default {
    name: 'HeroSectionTitle'
}
</script>

<style scoped>
    .section {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .section__title {
        color: #515667;
        font-size: 25px;
        padding-bottom: 40px;
        line-height: 1.7;
        font-weight: 700;
    }
    .section__img {
        padding-bottom: 10px;
    }
    .section__btn,
    .section__btn:link,
    .section__btn:visited {
        outline: none;
        border: none;
        text-decoration: none;
        display: inline-block;
        padding: 10px 70px;
        border-radius: 37px;
        font-size: 16px;
        font-weight: 700;
        position: relative;
        transition: all .2s;
        cursor: pointer;
        background: #629A9D;
        color: #fff;
    }

    .section__btn::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 3rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;
    }
    .section__btn:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba(#000, .2);
    }
    .section__btn:hover::after {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
    }
    .section__btn:active,
    .section__btn:focus {
        transform: translateY(-1px);
        box-shadow: 0 .5rem 1rem rgba(#000, .2);
    }

    @media screen and (max-width: 1000px) {
        .section {
            flex: 0 0 100%;
            max-width: 100%;
            padding-top: 40px;
        }
        .section__title {
            font-size: 20px;
        }
        .section__btn,
        .section__btn:link,
        .section__btn:visited {
            font-size: 15px;
            padding: 10px 50px;
        }
    }
</style>
