var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section illustration wow zoomIn animated",
    attrs: {
      "data-wow-delay": "1s",
      "data-wow-duration": "1s"
    }
  }, [_c('img', {
    staticClass: "section-image",
    attrs: {
      "src": require("../../../../../public/assets/images/website/kareem/images.png"),
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }