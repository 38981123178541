<template>
    <div class="section illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="1s">
        <img class="section-image" src="../../../../../public/assets/images/website/kareem/images.png" alt="">
    </div>
</template>

<script>
export default {
    name: 'HeroSectionImages'
}
</script>

<style scoped>
    .section {
        padding-top: 80px;
        flex: 0 0 35%;
        max-width: 35%;
    }
    .section-image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    @media screen and (max-width: 1000px) {
        .section {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
</style>