<template>
    <div class="section-kareem">
        <HeroSectionTitle />
        <HeroSectionImages />
    </div>
</template>

<script>
import HeroSectionTitle from './HeroSectionTitle.vue';
import HeroSectionImages from './HeroSectionImages.vue';
export default {
    name: "HeroSection",
    components: { HeroSectionTitle, HeroSectionImages }
}
</script>

<style scoped>
.section-kareem {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .section-kareem {
        flex-direction: column-reverse;
    }
}
</style>