var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section illustration wow fadeInUp animated",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('img', {
    staticClass: "section__img",
    attrs: {
      "src": require("../../../../../public/assets/images/website/kareem/logo.svg"),
      "alt": "logo"
    }
  }), _c('h1', {
    staticClass: "section__title"
  }, [_vm._v(" بوابة التبرعات الجديدة في مؤسسة التميز التنموية قم بتصفح الفرص والحالات و تعرف على الحالات الأشد حاجةً فالآن جميع فرص التبرع في مكانٍ واحد ! ")]), _c('a', {
    staticClass: "section__btn",
    attrs: {
      "href": "https://kareem.t-tamayoz.com/",
      "target": "_blank"
    }
  }, [_vm._v("تبرع الآن")])]);
}]

export { render, staticRenderFns }